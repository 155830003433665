<template>
  <div class="page page-myapp">
    <div class="page-content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>我的应用</el-breadcrumb-item>
      </el-breadcrumb>
      <el-row>
        <el-col :span="16">
          <el-button type="primary" @click="$router.push({name: 'createapp'})">创建新应用</el-button>
        </el-col>
        <el-col :span="8">
          <el-input v-model="keyword" placeholder="请输入关键字">
            <template #append>
              <el-button icon="el-icon-search" @click="getData"></el-button>
            </template>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="tableData" border v-loading="loadingCount>0" style="width: 100%">
        <el-table-column prop="name" label="应用名称" width="180"></el-table-column>
        <el-table-column prop="appId" label="APPID" width="180"></el-table-column>
        <!-- <el-table-column prop="secretAccessKey" label="APISecret" width="180"></el-table-column>
        <el-table-column prop="accessKey" label="APIKey" width="180"></el-table-column> -->
        <el-table-column prop="pubKey" label="公钥"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
      </el-table>
      <el-row type="flex" justify="end">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </el-row>
    </div>
  </div>
</template>

<script>
import publicUrl from '../../assets/js/interface'

export default {
  name: 'page-myapp',
  data () {
    return {
      keyword: '',
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      loadingCount: 0
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.loadingCount++
      this.tableData = []
      this.$ajax.get(publicUrl.appList, {
        params: {
          pageNo: this.currentPage,
          pageSize: this.pageSize,
          keyword: this.keyword
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.tableData = (res.data.data || {}).result || []
          this.total = (res.data.data || {}).totalCount || 0
          if (this.tableData === 0 && this.currentPage > 1) {
            this.currentPage = 1
            this.getData()
          }
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    },
    handleSizeChange (val) {
      this.pageSize = val
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.page-myapp {
  padding-top: 40px;
  .page-content {
    width: 900px;
    margin: 0 auto;
    .el-breadcrumb,
    .el-row,
    .el-table {
      margin-bottom: 20px;
    }
  }
}
</style>
